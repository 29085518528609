import axios from "axios";

interface Args {
  userId: string;
  accessToken: string;
}

async function getUserFocus(key: string, args: Args) {
  const { userId, accessToken } = args;

  const { data } = await axios.get(
    `${process.env.GATSBY_SERVER_URL}/focus/${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return data;
}

export default getUserFocus;
