import { ProgressionBlock } from "../components/CustomWorkoutForm/models";

interface Body {
  userId: string;
  workout: {
    progressionBlocks: ProgressionBlock[];
  };
}

function createInAppWorkout(accessToken: string, body: Body) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/custom-workouts/in-app`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export default createInAppWorkout;
