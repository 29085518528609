import React, { useContext, useState, useEffect } from "react";
import WorkoutContext from "../../context/WorkoutContext";
import { navigate } from "gatsby";
import { useLockBodyScroll } from "react-use";
import Modal from "../base/Modal";

function ContinueWorkoutModal() {
  const { workout, endWorkout } = useContext(WorkoutContext);
  const [shouldDisplayModal, setModalDisplayState] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (workout) {
      timeout = global.setTimeout(() => {
        return setModalDisplayState(true);
      }, 100);
    }

    return function () {
      clearTimeout(timeout);
    };
  }, [workout]);

  useLockBodyScroll(shouldDisplayModal);
  if (!shouldDisplayModal) return null;

  return (
    <Modal
      isOpen={shouldDisplayModal}
      heading="Workout In Progress"
      message="You've got a workout in progress. If you want, you can continue
  where you left off. Otherwise, you can end your workout."
      primaryAction={{
        text: "Continue Workout",
        handleAction: () => {
          navigate("/workout");
          setModalDisplayState(false);
        }
      }}
      secondaryAction={{
        text: "End Workout",
        handleAction: () => {
          endWorkout();
          setModalDisplayState(false);
        }
      }}
    />
  );
}

export default ContinueWorkoutModal;
