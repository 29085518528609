import { FileFormat } from "../models/workouts";
import { ProgressionBlock } from "../components/CustomWorkoutForm/models";

interface Body {
  userId: string;
  fileFormat: FileFormat;
  workout: {
    progressionBlocks: ProgressionBlock[];
  };
}

function createCustomWorkout(accessToken: string, body: Body) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/custom-workouts`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export default createCustomWorkout;
