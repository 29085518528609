import React, { useState, useEffect } from "react";
import SelectInput from "../forms/SelectInput";
import { CloseIconWhite } from "../../icons";
import { formFields } from "./helpers";
import { ProgressionBlock, GroupType } from "./models";
import Icon from "../Icon";
import Button from "../Button";
import { usePrevious } from "react-use";
import "../../styles/ExerciseGroup.css";

const GROUP_TYPES: Record<GroupType, number> = {
  single: 1,
  double: 2,
  triple: 3,
  pyramid: 1
};

const { Section, Field } = formFields;

interface ExerciseGroupProps {
  availableProgressions: {
    value: string;
    label: string;
  }[];
  handleGroupTypeChange: (type: GroupType) => void;
  handleClose: () => void;
  index: number;
  totalGroupCount: number;
  selectedProgressions: string[];
}

function ExerciseGroup(props: ExerciseGroupProps) {
  const {
    handleClose,
    index,
    availableProgressions,
    handleGroupTypeChange,
    selectedProgressions,
    totalGroupCount
  } = props;
  const [selectedGroup, selectGroup] = useState<GroupType>("single");
  const prevGroupCount = usePrevious(totalGroupCount);
  const progressionCount = GROUP_TYPES[selectedGroup];
  const arrayFixedLength = Array.from(Array(progressionCount).keys());

  function cycleBlockType() {
    if (selectedGroup === "single") {
      handleGroupTypeChange("double");
      return selectGroup("double");
    }

    if (selectedGroup === "double") {
      handleGroupTypeChange("triple");
      return selectGroup("triple");
    }

    handleGroupTypeChange("single");
    return selectGroup("single");
  }

  useEffect(() => {
    const progressionLength = selectedProgressions.length;

    if (prevGroupCount === totalGroupCount) return;

    if (progressionLength === 2) {
      handleGroupTypeChange("double");
      return selectGroup("double");
    }

    if (progressionLength === 3) {
      handleGroupTypeChange("triple");
      return selectGroup("triple");
    }

    handleGroupTypeChange("single");
    return selectGroup("single");
  }, [
    selectedProgressions,
    prevGroupCount,
    totalGroupCount,
    handleGroupTypeChange
  ]);

  return (
    <Section<ProgressionBlock> name={index}>
      <div className="ExerciseGroup__title">
        <h3 style={{ margin: 0 }}>Exercise Group {index + 1}</h3>

        <div className="ExerciseGroup__actions">
          <Button size="small" handleClick={cycleBlockType}>
            {selectedGroup}
          </Button>

          <Icon
            src={CloseIconWhite}
            title="remove exercise group"
            containerWidth={16}
            handleClick={handleClose}
          />
        </div>
      </div>
      {arrayFixedLength.map(i => {
        const zIndex = 1000 - (index * 3 + i) - 3; // this is due to a quirk with React Select's z-index

        const value = availableProgressions.find(
          ({ value }) => selectedProgressions[i] === value
        );

        return (
          <Field<string[]>
            key={i}
            name="progressions"
            label="progression:"
            value={value ?? null}
            placeholder="Choose a progression..."
            zIndex={zIndex}
            handleChange={(
              setValue: (values: string[]) => void,
              newValue: string
            ) => {
              const progressionsCopy = [...selectedProgressions];
              progressionsCopy[i] = newValue;
              return setValue(progressionsCopy);
            }}
            id="progression"
            options={availableProgressions}
            component={SelectInput}
          />
        );
      })}
    </Section>
  );
}

export default ExerciseGroup;
