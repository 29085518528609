import React from "react";
import useGetUserSubscription from "../../hooks/useGetUserSubscription";
import Button from "../Button";
import { ProgressionBlock, GroupType } from "./models";
import { formFields } from "./helpers";
import {
  standardProgressions,
  premiumProgressions
} from "./workoutBuilderProgressions";
import ExerciseGroup from "./ExerciseGroup";
import { Link } from "gatsby";

const { Repeat } = formFields;

function AdvancedWorkoutForm() {
  const { data } = useGetUserSubscription();

  const allProgressions = [...standardProgressions, ...premiumProgressions!];

  const availableProgressions = data?.subscription?.active
    ? allProgressions
    : standardProgressions;

  if (!data?.subscription?.active) {
    return (
      <p data-testId="premium-upsell">
        You need <Link to="/pricing">Cali Skills Premium</Link> to get access to
        our Advanced Workout Builder.
      </p>
    );
  }

  return (
    <Repeat<ProgressionBlock> name="progressionBlocks">
      {(arr, { push, remove, setValue }) => {
        if (arr.length === 0) {
          return (
            <>
              <img
                style={{ width: "100%", marginBottom: "16px" }}
                alt="meme about performing no exercises."
                src="https://i.imgflip.com/458cge.jpg"
                title="meme about performing no exercises."
              />
              <div style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  handleClick={() =>
                    push({ blockType: "single", progressions: [] })
                  }
                >
                  Add another
                </Button>
              </div>
            </>
          );
        }

        return (
          <>
            {arr.map((progressionBlock, i) => {
              return (
                <ExerciseGroup
                  key={i}
                  index={i}
                  handleGroupTypeChange={(type: GroupType) => {
                    const currentValue = [...arr];
                    currentValue[i].blockType = type;
                    return setValue(currentValue);
                  }}
                  handleClose={() => remove(i)}
                  availableProgressions={availableProgressions}
                  selectedProgressions={progressionBlock.progressions}
                  totalGroupCount={arr.length}
                />
              );
            })}
            <div style={{ textAlign: "right" }}>
              <Button
                type="button"
                handleClick={() =>
                  push({ blockType: "single", progressions: [] })
                }
              >
                Add another
              </Button>
            </div>
          </>
        );
      }}
    </Repeat>
  );
}

export default AdvancedWorkoutForm;
