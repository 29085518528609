import React, { useContext, useState } from "react";
import ReactGA from "react-ga";
import skillTreeData from "../../data/skills";
import Button from "../Button";
import useAuth from "../../hooks/useAuth";
import createCustomWorkout from "../../api/createCustomWorkout";
import createInAppWorkout from "../../api/createCustomInAppWorkout";
import Loader from "../Loader";
import AutomaticDownloadButton from "../AutomaticDownloadButton";
import { Link } from "gatsby";
import { FileFormat } from "../../models/workouts";
import { navigate } from "gatsby";
import generateSnackbar from "../../helpers/generateSnackbar";
import BasicWorkoutForm from "./BasicWorkoutForm";
import WorkoutBuilderSelector from "./WorkoutBuilderSelector";
import { formatTreeId, formFields } from "./helpers";
import AdvancedWorkoutForm from "./AdvancedWorkoutForm";
import { ProgressionBlock, FormValue } from "./models";
import WorkoutContext from "../../context/WorkoutContext";
import useFocusQuery from "../../hooks/useFocusQuery";

export interface WorkoutConfig {
  fileFormat: FileFormat;
}

function getSelectValueFromFocus(treeId: string | null) {
  if (!treeId) return null;

  const formattedTreeId = formatTreeId(treeId);

  const skillTree = skillTreeData.find(x => treeId === x.treeId);

  if (!skillTree?.title) {
    return null;
  }

  return {
    value: formattedTreeId,
    label: skillTree.title
  };
}

const arrayFixedLength = Array.from(Array(3).keys());

const validateProgressionBlocks = (p: ProgressionBlock[]) => {
  return p.filter(x => {
    return x.progressions.filter(x => x).length;
  }).length;
};

function automaticDownload(href: string) {
  const downloadButton = document.getElementById(
    "automatic-download-button"
  )! as HTMLAnchorElement;

  downloadButton.href = href;
  downloadButton.click();
}

const { Form } = formFields;

function CustomWorkoutForm() {
  const {
    getAccessToken,
    getUserId,
    getAuthenticationState,
    tokenRenewalComplete
  } = useAuth();
  const { data, status } = useFocusQuery();
  const { startWorkout } = useContext(WorkoutContext);

  const [isRetrievingWorkout, setRetrievingState] = useState(false);
  const [selectedBuilder, setSelectedBuilder] = useState("basic");

  const isLoggedIn = getAuthenticationState();

  function generateWorkout(formValue: FormValue) {
    setRetrievingState(true);

    const { progressionBlocks } = formValue;

    const validatedProgressionBlocks = progressionBlocks.map(block => {
      return {
        ...block,
        progressions: block.progressions.filter(x => x)
      };
    });

    const filteredProgressionBlocks = validatedProgressionBlocks.filter(
      block => {
        return block.progressions.length > 0;
      }
    );

    const body = {
      userId: getUserId(),
      workout: {
        progressionBlocks: filteredProgressionBlocks
      }
    };

    return body;
  }

  function handleSurpassDailyLimit(dailyLimit: number) {
    generateSnackbar({
      type: "error",
      message: `Looks like you're on a free plan. You can only create ${dailyLimit} workout(s) per day`,
      actionMessage: "Upgrade",
      handleAction: () => navigate("/pricing")
    });

    return setRetrievingState(false);
  }

  function handleUnauthorisedUse() {
    generateSnackbar({
      type: "error",
      message: `Downloading your workout is a premium feature.`,
      actionMessage: "Upgrade",
      handleAction: () => navigate("/pricing")
    });

    return setRetrievingState(false);
  }

  function handleGenericWorkoutProblem() {
    generateSnackbar({
      message: "We had a problem creating your workout",
      hideAfter: 10000,
      type: "error"
    });

    return setRetrievingState(false);
  }

  async function downloadWorkout(formValue: FormValue) {
    const requestBody = generateWorkout(formValue);

    const body = {
      ...requestBody,
      fileFormat: "pdf" as FileFormat
    };

    try {
      const res = await createCustomWorkout(getAccessToken(), body);

      if (res.status === 403) {
        return handleUnauthorisedUse();
      }

      if (res.status >= 400) {
        return handleGenericWorkoutProblem();
      }

      const data = await res.json();

      ReactGA.event({
        category: "Workout Generator",
        action: "Generated a workout",
        label: `Generated a custom workout as pdf`
      });

      setRetrievingState(false);
      automaticDownload(data.s3URL);
    } catch (e) {
      console.error(e);
      return handleGenericWorkoutProblem();
    }
  }

  async function performWorkout(formValue: FormValue) {
    const requestBody = generateWorkout(formValue);
    try {
      const res = await createInAppWorkout(getAccessToken(), requestBody);

      if (res.status === 403) {
        const { dailyLimit } = await res.json();
        return handleSurpassDailyLimit(dailyLimit);
      }

      if (res.status >= 400) {
        return handleGenericWorkoutProblem();
      }

      const data = await res.json();

      ReactGA.event({
        category: "Workout Generator",
        action: "Generated a workout",
        label: `Generated a custom in-app workout`
      });

      setRetrievingState(false);
      startWorkout(data.workout);
      navigate("/workout");
    } catch (e) {
      console.error(e);
      return handleGenericWorkoutProblem();
    }
  }

  const defaultValues = arrayFixedLength.map(i => {
    if (!data || i !== 0) {
      return null;
    }

    return getSelectValueFromFocus(data.treeId);
  });

  const progressionBlocks: ProgressionBlock[] = defaultValues.map(val => {
    if (!val || !val.value) {
      return { progressions: [""], blockType: "single" };
    }

    return { progressions: [val.value], blockType: "single" };
  });

  if (status === "loading" || !tokenRenewalComplete) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    return (
      <div style={{ margin: "32px auto", maxWidth: "375px" }}>
        <h3>
          You need to <Link to="/login/">Sign up</Link> to create a workout
        </h3>
      </div>
    );
  }

  return (
    <Form onSubmit={() => {}} initialValue={{ progressionBlocks }}>
      {yaflProps => {
        const { progressionBlocks } = yaflProps.formValue as FormValue;
        const areProgressionsValid = validateProgressionBlocks(
          progressionBlocks
        );

        const isDisabled = !areProgressionsValid || isRetrievingWorkout;

        return (
          <>
            <div style={{ margin: "0 auto 32px", maxWidth: "320px" }}>
              <WorkoutBuilderSelector
                selectedBuilder={selectedBuilder}
                handleClick={val => setSelectedBuilder(val)}
              />
              {selectedBuilder === "advanced" ? (
                <AdvancedWorkoutForm />
              ) : (
                <BasicWorkoutForm />
              )}
              <br />
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: "16px"
                }}
              >
                <Button
                  disabled={isDisabled}
                  handleClick={() =>
                    downloadWorkout(yaflProps.formValue as FormValue)
                  }
                >
                  Download the workout
                </Button>

                <div style={{ width: "16px" }} />
                <Button
                  disabled={isDisabled}
                  handleClick={() =>
                    performWorkout(yaflProps.formValue as FormValue)
                  }
                >
                  Perform the workout
                </Button>

                <AutomaticDownloadButton />
              </div>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export default CustomWorkoutForm;
