import skillTreeData from "../../data/skills";
import { formatTreeId } from "./helpers";

interface Progression {
  value: string;
  label: string;
}

export const standardProgressions = skillTreeData
  .map(({ title, treeId, premium }) => {
    if (premium) return null;

    const formattedId = formatTreeId(treeId);

    return {
      value: formattedId,
      label: title
    };
  })
  .filter((x): x is Progression => !!x);

export const premiumProgressions = skillTreeData
  .map(({ title, treeId, premium }) => {
    if (!premium) return null;

    const formattedId = formatTreeId(treeId);

    return {
      value: formattedId,
      label: title
    };
  })
  .filter((x): x is Progression => !!x);
