import React from "react";
import SelectInput from "../forms/SelectInput";
import useGetUserSubscription from "../../hooks/useGetUserSubscription";
import Button from "../Button";
import {
  standardProgressions,
  premiumProgressions
} from "./workoutBuilderProgressions";
import { formFields } from "./helpers";
import { ProgressionBlock } from "./models";
import { Link } from "gatsby";

const { Repeat, Section, Field } = formFields;

function StandardWorkoutForm() {
  const { data: subscriptionData } = useGetUserSubscription();

  const allProgressions = [...standardProgressions, ...premiumProgressions];

  if (!subscriptionData?.subscription?.active) {
    return (
      <p data-testId="premium-upsell">
        You need <Link to="/pricing">Cali Skills Premium</Link> to get access to
        our Workout Builder.
      </p>
    );
  }

  return (
    <Repeat<ProgressionBlock> name="progressionBlocks">
      {(arr, { push }) => {
        return (
          <>
            {arr.map((_, i) => {
              const value = allProgressions.find(
                ({ value }) => arr[i].progressions[0] === value
              );

              return (
                <Section<ProgressionBlock> name={i} key={i}>
                  <Field<string[]>
                    name="progressions"
                    label="progression:"
                    value={value ?? null}
                    placeholder="Choose a progression..."
                    zIndex={1000 - i - 3}
                    id="progression"
                    handleChange={(
                      setValue: (values: string[]) => void,
                      newValue: string
                    ) => setValue([newValue])}
                    options={allProgressions}
                    component={SelectInput}
                  />
                </Section>
              );
            })}
            <div style={{ textAlign: "right" }}>
              <Button
                type="button"
                handleClick={() =>
                  push({ progressions: [""], blockType: "single" })
                }
              >
                Add another
              </Button>
            </div>
          </>
        );
      }}
    </Repeat>
  );
}

export default StandardWorkoutForm;
