import { FocusData } from "../models";
import useAuth from "../hooks/useAuth";
import getUserFocus from "../api/getUserFocus";
import { useQuery } from "react-query";

type TKey = "focus";

interface TVariables {
  userId: string;
  accessToken: string;
}

function useFocusQuery() {
  const {
    getUserId,
    getAccessToken,
    tokenRenewalComplete,
    getAuthenticationState
  } = useAuth();

  const shouldFetch = getAuthenticationState() && tokenRenewalComplete;

  const userId = shouldFetch ? getUserId() : "";
  const accessToken = shouldFetch ? getAccessToken() : "";

  const { status, data, error } = useQuery<FocusData, [TKey, TVariables]>(
    [
      "focus",
      {
        userId,
        accessToken
      }
    ],
    getUserFocus,
    {
      enabled: shouldFetch
    }
  );

  const newData = data ? { treeId: data.tree_id } : null;

  return {
    status,
    data: newData,
    error
  };
}

export default useFocusQuery;
