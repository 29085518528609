import React from "react";
import WorkoutForm from "../components/CustomWorkoutForm/WorkoutForm";
import TextPageLayout from "../components/layouts/TextPageLayout";
import ContinueWorkoutModal from "../components/modals/ContinueWorkoutModal";
import { Link } from "gatsby";
import SEO from "../components/SEO";

function Workouts() {
  return (
    <TextPageLayout title="Bodyweight Workout Builder">
      <SEO
        path="/workouts/"
        title="Workout Builder | Cali Skills"
        description="Pick the progressions you want to work on and we'll create a bodyweight workout just for you."
      />
      <h3>Which progressions would you like to work on today?</h3>
      <p>
        Choose the progressions you want to work on, and we'll pick the
        exercises based on your current progress.
      </p>

      <p>Want more control? Try the Advanced Workout Builder</p>
      <p>
        Want us to handle it all? Try a{" "}
        <Link to="/workouts/workouts-list">a ready-made workout routine</Link>.
      </p>
      <WorkoutForm />
      <ContinueWorkoutModal />
      <p>
        Tip: If you choose the same progression multiple times, we'll swap in
        some regressions.
      </p>
    </TextPageLayout>
  );
}

export default Workouts;
