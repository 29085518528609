import { createFormContext } from "yafl";

export function formatTreeId(treeId: string) {
  const indexOfTree = treeId.indexOf("-tree");

  return indexOfTree >= 0 ? treeId.substring(0, indexOfTree) : treeId;
}

const { Form, Field, Repeat, Section } = createFormContext();

export const formFields = {
  Form,
  Field,
  Repeat,
  Section
};
