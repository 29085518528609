import React from "react";
import ButtonGroup from "../base/ButtonGroup";

interface Props {
  handleClick: (val: string) => void;
  selectedBuilder: string;
}

function WorkoutBuilderSelector(props: Props) {
  return (
    <ButtonGroup
      size="small"
      buttonOne={{ id: "basic", text: "Basic" }}
      buttonTwo={{ id: "advanced", text: "Advanced" }}
      selectedButton={props.selectedBuilder}
      handleClick={props.handleClick}
      disabledButtons={[false, false]}
    />
  );
}

export default WorkoutBuilderSelector;
